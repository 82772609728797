import React from 'react';
import { stringify } from 'rebem-classname';

export default class ListadoLocalidadSinPropiedades extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const block = "MainListado";
        return (
            <div className={`row ${stringify({block, elem:"display"})}`} style={{ minHeight: window.innerHeight }}>
                <div className={`${this.props.colListado} ${stringify({block, elem:"listado"})}`}>
                    <div className={stringify({block, elem:"titulo-listado-loc-cercanas"})}>No hay alojamientos en esta localidad, le recomendamos las siguientes localidades cercanas</div>
                    <div className="row">
                        {
                            this.props.dataLocalidadesCercanas.map((p) =>
                                <div key={p.id} className={`col-md-4 col-sm-6 col-xs-12 ${stringify({block, elem:"card-loc-cercanas"})}`}>
                                    <div className={stringify({block, elem:"contenedor-loc-cercanas"})}>
                                        <h2 className={stringify({block, elem:"titulo-loc-cercanas"})}>{p.localidad}</h2>
                                        <div className={stringify({block, elem:"info-loc-cercanas"})}> a {p.distanciaKm.toFixed(2)}km de distancia</div>
                                        <div className={stringify({block, elem:"info-loc-cercanas"})}>
                                            {p.cantPropiedades} {p.cantPropiedades > 1 ? 'Alojamientos' : 'Alojamiento'}
                                        </div>
                                        <div className={stringify({block, elem:"boton-loc-cercanas"})}>
                                            <a href={p.link} target='_blank' className="btn btn--sin-radius btn-naranja">Ver Alojamientos</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}