/* @flow */
import React from 'react';
import LinksHelper from '../../helpers/LinksHelper';

export default class BreadcrumListado extends React.Component{ 
	render(){
		const lugar = this.props.lugar;
		if(lugar){
			let breadcrumb = [];
			switch(lugar.tipo){
				case 'localidad':
					breadcrumb.push({anchor:lugar.pais,href:LinksHelper.pais(lugar.pais)});
					breadcrumb.push({anchor:lugar.provincia,href:LinksHelper.provincia(lugar.provincia)});
					breadcrumb.push({anchor:lugar.nombre,href:LinksHelper.localidad(lugar.provincia, lugar.nombre)});
					break;
				case 'ciudad':
					breadcrumb.push({anchor:lugar.pais,href:LinksHelper.pais(lugar.pais)});
					breadcrumb.push({anchor:lugar.nombre,href:LinksHelper.localidad(lugar.pais,lugar.nombre)});
					break;
				case 'provincia':
					breadcrumb.push({anchor:lugar.pais,href:LinksHelper.pais(lugar.pais)});
					breadcrumb.push({anchor:lugar.nombre,href:LinksHelper.provincia(lugar.nombre)});
					break;
				case 'país':
					breadcrumb.push({anchor:lugar.nombre,href:LinksHelper.pais(lugar.nombre)});
					break;
				default: 
					return null;
			}
			let beadcrumsSinArgentina = breadcrumb.filter((item) => item.anchor !== 'Argentina'); //quito Argentina de los breadcrumb
			let items = beadcrumsSinArgentina.map((i, index) => <li className="BreadcumListado__items" key={index}><a href={i.href}>{i.anchor}</a></li>);
			return(
				<div className="BreadcumListado">
					<ul className="BreadcumListado__lista ul-limpia">
						<li className="BreadcumListado__items">
						 	<span>Argentina</span>	
						</li>
						{ items }
						<li>
							<span>(<b>{this.props.cantidad}</b> alojamientos encontrados para vos)</span>
						</li>
					</ul>
				</div>
			);
		} else {
			return null;
		}
	}
}