import React from 'react';
import MapaPie from './Mapa/MapaPie';
import Sticky from 'react-stickynode';
import MapBoxListado from "../Mapbox/MapBoxListado.jsx";
import pick from 'lodash/pick';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import UrlHelper from '../../helpers/Filtros/UrlHelper';
import axios from 'axios';
import AACargando from '../Commons/AACargando';
import { stringify } from 'rebem-classname';

export default class MapaListado extends React.Component{
	constructor(props) {
		super(props);
		this.AbrirMapaPie = this.AbrirMapaPie.bind(this);
		this.CerrarMapaPie = this.CerrarMapaPie.bind(this);
		this.onChangeClick = this.onChangeClick.bind(this);
		this.getPropiedades = this.getPropiedades.bind(this); 
		this.urlHelper = new UrlHelper();

		this.state = {
			referencia: null,
			mostrarMapaPie: false,
			propiedades: null,
			lugarAnterior: null,
			filtroAnterior: null
		}
	}

	componentDidMount(){
		this.getPropiedades();
	}

	componentDidUpdate(prevProps){
		// Se actualiza el mapa si esta en el "/buscar" y cambia por alguna ubicacion ó si viene lugar y busca una nueva ubicacion 
		if(this.props.lugar != prevProps.lugar || (this.props.lugar && prevProps.lugar && prevProps.lugar.nombre != this.props.lugar.nombre)){
			this.getPropiedades()
		}

		if(prevProps.filtros != this.props.filtros){
			this.getPropiedades()
		}
	}

	getPropiedades(){
		const { lugar, page, filtros } = this.props

		let prov = null
		if(lugar){
			if (filtros == this.state.filtroAnterior && lugar.nombre == this.state.lugarAnterior) {
				return;
			}
			
			if (lugar.tipo == 'localidad') {
				prov = lugar.provincia
			} else if (lugar.tipo == "provincia" || lugar.tipo == "país") {
				prov = lugar.nombre
			}
		}

		let datos = {
			filtros: filtros,
			localidad: lugar && lugar.tipo == 'localidad' ? lugar.nombre : null,
			// Si no viene lugar muestro el mapa de toda argentina
			provincia: lugar ? prov : 'Argentina',
			queryString: { pag: page, p: undefined }
		}

		axios
		.get(this.urlHelper.buildAjaxMapaUrl(datos))
		.then((response) => {
			this.setState({
				propiedades: response.data,
				lugarAnterior: this.props.lugar ? this.props.lugar.nombre : null,
				filtroAnterior: this.props.filtros
			});
		})
		.catch((err) => console.log(err));
	}

	AbrirMapaPie(){
		this.setState({
			mostrarMapaPie: true
		});
	}

	CerrarMapaPie(){
		this.setState({
			mostrarMapaPie: false
		});
		this.setState({
			referencia : ''
		});
	}

	onChangeClick(referencia){
		this.AbrirMapaPie();
		this.setState({
			referencia
		});
	}

	render(){
		const tamañoMenu = 50;
		const mapaHeight = window.innerHeight - tamañoMenu;

		let propMostrar = null;
		if(this.state.referencia!=null){
			propMostrar = this.state.referencia;
		}

		if (!this.state.propiedades) {
			return <div className={stringify({block})}>
				<div className={stringify({block, elem:"mapa"})} style={{ height: mapaHeight,	display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<AACargando/>
				</div>
			</div>
		}

		const propiedadParaPie = pick(find(this.state.propiedades, {referencia: propMostrar}), ['referencia','foto_listado','tipo','precio_minimo_calculado','capacidad_max','nombre_localidad','nombre_provincia','puntaje','titulo', 'link', 'precio_minimo']);
		const block = "MapaListado";

		const mapaStyle = {
			position: 'absolute',
			top: 0,
			bottom: 0,
			width: '100%',
			left: 0,
			height: '100%',
			zIndex: 0 
		};
		
		return(
			<div className={stringify({block})}>
				<Sticky enabled={!this.props.pantallaCompleta} top={tamañoMenu} bottomBoundary={'.MainListado'}>
					<div className={stringify({block, elem:"mapa"})} style={{height:mapaHeight}}>
						<MapBoxListado
							style={mapaStyle}
							propiedades={this.state.propiedades}
							key={this.state.propiedades.length + this.props.mostrar}
							onClickMarker={this.onChangeClick}
						/>
						<div className={stringify({block, elem:"pie", mods:{mapaSolo:this.props.pantallaCompleta}})}>
							<MapaPie 
								dual={!this.props.pantallaCompleta}
								propiedad={isEmpty(propiedadParaPie) ? null : propiedadParaPie} 
								CerrarMapaPie={this.CerrarMapaPie}
								mostrarMapaPie={this.state.mostrarMapaPie}
							/>
						</div>
					</div>
				</Sticky>
			</div>
		);
	}
}