import React from 'react';
import FiltrosDesktopContenido from './FiltrosDesktopContenido.jsx';
import SidebarMenu from '../../Commons/SidebarMenu';
import { stringify } from 'rebem-classname';

export default function FiltrosDesktop(props){
	const block='FiltrosDesktop';
	const buscarDesktop = <div className={stringify({block})}>
		<div className={`container-fluid ${stringify({block, elem:'contenedor-buscar-desktop', mods:{fade:props.mostrarFiltro}})}`}>
			<div className={stringify({block,mods:{buscarDesktopContenido:true}})}>
				<div className={stringify({block,mods:{buscarDesktopInline:true}})}>
					<div className={stringify({block, elem:'buscar-desktop-div-boton-cancel'})}>
						<button className={stringify({block, elem:'buscar-desktop-boton-cancel'})} onClick={props.onRestablecerFiltros}>
							<span>Reestablecer</span>
						</button>
					</div>
				</div>
				<div className={stringify({block, mods:{buscarDesktopInline:true}})}>
					<button className={stringify({block, elem:'buscar-desktop-boton'})} onClick={props.onBuscar}>Buscar</button>
				</div>
			</div>
		</div>
	</div>;

	return (
		<div className={stringify({block})} style={{display:'block'}}>
			<SidebarMenu side='left' 
				isVisible={props.mostrarFiltro} 
				onHide={props.showFiltro}
				className={block}
			> 
				<div className={stringify({block, elem:'contenido'})}>
					<FiltrosDesktopContenido
						showFiltro={props.showFiltro} 
						onClickCheck={props.onClickCheckFiltros}
						ofertasOnChangeFiltros={props.ofertasOnChangeFiltros}
						previajeOnChangeFiltros={props.previajeOnChangeFiltros}
						reservaOnChangeFiltros={props.reservaOnChangeFiltros}
						onUpdatePrecio={props.onUpdatePrecio}
						onChangePrecio={props.onChangePrecio}
						onChangePrecioFiltros={props.onChangePrecioFiltros}
						openCollpase={props.openCollpase}
						onClickCheckLocalidades={props.onClickCheckLocalidadesFiltros}
						onLugarClear={props.onLugarClear} 
						onLugarSelected={props.onLugarSelected}
						localidades={props.localidades}
						cochera={props.cochera}
						dormitorios={props.dormitorios}
						banos={props.banos}
						onPlus={props.onPlus}
						onMinus={props.onMinus}
						onRestablecerFiltros={props.onRestablecerFiltros}
						mostrarFiltro={props.mostrarFiltro}			
						onBuscar={props.onBuscar}
						conPileta={props.conPileta}
						sinPileta={props.sinPileta}
						PiletaExclusiva={props.PiletaExclusiva}
						Piletaclimatizada={props.Piletaclimatizada}
						Casas={props.Casas}
						Departamentos={props.Departamentos}
						Cabanas={props.Cabanas}
						Aparts={props.Aparts}
						Hoteleria={props.Hoteleria}
						Quintas={props.Quintas}
						Motorhomes={props.Motorhomes}
						Glampings={props.Glampings}
						ofertas={props.ofertas}
						previaje={props.previaje}
						reserva={props.reserva}
						conMascotas={props.conMascotas}
						conJovenes={props.conJovenes}
						wifi={props.wifi}
						tarjeta={props.tarjeta}
						desayuno={props.desayuno}
						limpieza={props.limpieza}
						toallas={props.toallas}
						sabanas={props.sabanas}
						spa={props.spa}
						juegosParaNinios={props.juegosParaNinios}
						restaurante={props.restaurante}
						min={props.min}
						max={props.max}
						currentValues={props.currentValues}
						servicios={ props.servicios}
						agregado1={props.agregado1}
						agregado2={props.agregado2}
						agregado3={props.agregado3}
						filtrosAplicados={props.filtrosAplicados}
						localidades_seleccionadas={props.localidades_seleccionadas}
						localidad={props.localidad}
						referencia={props.referencia}
						barrio={props.barrio}
						lugar={props.lugar}
						onReferenciaChange={props.onReferenciaChange}
						onBarrioSelected={props.onBarrioSelected}
						onBarrioClear={props.onBarrioClear}
						aireAcondicionado={props.aireAcondicionado}
						calefaccion={props.calefaccion}
						microondas={props.microondas}
						televisor={props.televisor}
						lavarropa={props.lavarropa}
						alojamientoAccesible={props.alojamientoAccesible}
						alojamientosPremium={props.alojamientosPremium}
						alojamientosDestacados={props.alojamientosDestacados}
						seleccionesOtros={props.seleccionesOtros}
						hayTroyaEnElListado={props.hayTroyaEnElListado}
						precioPromedio={props.precioPromedio}
					/>
				</div>
			</SidebarMenu>
			{buscarDesktop}
		</div>
	);
}