import React, { useEffect, useContext, useRef } from 'react';
import FiltrosDesktop from './Filtros/FiltrosDesktop.jsx';
import PaginatorListado from '../../components/Commons/PaginatorListado.jsx';
import AnuncioCardListado from './AnuncioCardListado.jsx';
import CardListadoVerMasAnuncios from './CardListado/CardListadoVerMasAnuncios.jsx';
import MapaListado from './MapaListado';
import CardListadoBannerBrasil from './CardListado/CardListadoBannerBrasil';
import { stringify } from 'rebem-classname';
import Context  from '../Login/ContextUser/Context';

export default function ListadoRenderItems(props) {
    const block = "MainListado";
    const contextGlobal = useContext(Context)
    useEffect(() => {
        const { getSession } = contextGlobal;
        getSession()
    },[]);
    return (
        <div className={`row ${stringify({block, elem:"display"})}`}>
            <FiltrosDesktop
                {...props.propsFiltros}
            />
            <div className={`${props.colListado} ${stringify({block, elem:"listado", mods:{ cargando: props.estaCargando }})}`}>
                {props.destacada ? <div className="row">
                    <AnuncioCardListado
                        showModalFavorito={props.showModalFavorito}
                        listParaGTM={props.listParaGTM}
                        propiedad={props.destacada}
                        estaCargando={props.estaCargando}
                        mostrar={props.mostrar}
                        personas={props.personas}
                        bebes={props.bebes}
                        columnas={props.colCards + " " + props.colCardDestacada + " col-sm-6 col-xs-12"}
                        mostrarMapa={props.mostrar == "dual"}
                        desgloseDePrecio={props.desgloseDePrecio}
                        arrayParaLink={props.arrayParaLink}
                        fechasDeBusqueda={props.dates}
                        lugar={props.lugar}
                    />
                </div> : null}
                <div className="row">
                    {
                        props.propiedades.map((p, i) => <AnuncioCardListado
                            key={`${p.esLineaGold ? 'g' : ''}${p.esLineaDestacados ? 'd' : ''}${p.referencia}`}
                            listParaGTM={props.listParaGTM}
                            propiedad={p}
                            showModalFavorito={props.showModalFavorito}
                            estaCargando={props.estaCargando}
                            mostrar={props.mostrar}
                            personas={props.personas}
                            bebes={props.bebes}
                            columnas={props.colCards + " col-sm-6 col-xs-12"}
                            mostrarMapa={props.mostrar == "dual"}
                            arrayParaLink={props.arrayParaLink}
                            desgloseDePrecio={props.desgloseDePrecio}
                            fechasDeBusqueda={props.dates}
                            lugar={props.lugar}
                            index={i}
                        />)
                    }
                    {
                        !props.listadoEspecial && props.hayAnunciosSinPrecio && !props.aceptaAnunciosSinPrecios && props.activePage == props.items ?
                            <CardListadoVerMasAnuncios
                                columna={props.colCards + " col-sm-6 col-xs-12"}
                                onBuscarPagina={props.onBuscarPagina}
                                paginaActual={props.activePage}
                                mostrarMapa={props.mostrar == "dual"}
                                goToPaginator={()=> handlePaginator()}
                            />
                             :  null
                    }
                </div>
                { 
                    // si esta habilitado en config cat, si esta en el listado de brasil y si esta en la ultima pagina del listado muestro el banner
                    props.showBanner && props.lugar && props.lugar.nombre == 'Brasil' && props.activePage == props.items ? 
                        <CardListadoBannerBrasil /> 
                    : null
                }
                <div className={stringify({block, elem:"paginador"})} id="paginador">
                    <PaginatorListado
                        prev={props.prev ? props.prev : undefined}
                        next={props.next ? props.next : undefined}
                        ellipsis="..."
                        items={props.items}
    					activePage={props.activePage}
                        maxButtons={5}
                        onSelect={props.onSelect}
                    />
                </div>
            </div>
            <div className={`${props.colMapa} ${stringify({block, elem:"mapa"})}`}>
                {props.mostrar != 'listado' && <MapaListado 
                    pantallaCompleta={props.mostrar == 'mapa'}
                    referenciaHover={props.propiedadHoverRef}
                    mostrar={props.mostrar}
                    filtros={props.filtrosUrl}
                    lugar={props.lugar}
                    page={props.activePage}
                />}
            </div>
        </div>
    );
}