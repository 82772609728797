/* @flow */
import React from 'react';
import { stringify } from 'rebem-classname';
import PaginatorListado from '../../components/Commons/PaginatorListado.jsx';
import AnuncioCardListado from './AnuncioCardListado.jsx';

export default function ListadoCustom(props) {
    const block = "MainListado";

    return (
        <div className={`container-fluid ${stringify({block})}`}>
            {props.paginator.hotelesMarDel.length ? <div>
                <div className={`col-md-8 ${stringify({block, elem:"header"})}`} style={{ marginBottom: 35}}>
                    <h1 className={stringify({block})}>Hoteles baratos en Mar del Plata hasta 300 $ por día</h1>
                </div>

                <div className={`row ${stringify({block, elem:"display"})}`}>
                    <div className={`col-md-12 ${stringify({block, elem:"listado", mods:{ cargando: props.estaCargando }})}`}>
                        <div className="row">
                            {
                                props.paginator.hotelesMarDel.map((p, i) => <AnuncioCardListado
                                    key={`${p.esLineaGold ? 'g' : ''}${p.esLineaDestacados ? 'd' : ''}${p.referencia}`}
                                    listParaGTM={props.listParaGTM}
                                    propiedad={p}
                                    columnas={"col-md-3 col-sm-6 col-xs-12"}
                                    mostrarMapa={false}
                                    propiedadHover={null}
                                    propiedadOut={null}
                                    arrayParaLink={null}
                                    fechasDeBusqueda={[props.startDate, props.endDate]}
                                    index={i}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </div> : null}

            <div className={`col-md-12 ${stringify({block, elem:"header"})}`} style={{ marginBottom: 35 }}>
                <h2 className={stringify({block})}> Las mejores alternativas a Hoteles en Mar Del Plata - Precios Accesibles y Amplia Disponibilidad</h2>
            </div>
            
            <div className={`row ${stringify({block, elem:"display"})}`}>
                <div className={`col-md-12 ${stringify({block, elem:"listado", mods:{ cargando: props.estaCargando }})}`}>
                    <div className="row">
                        {
                            props.propiedades.map((p, i) => <AnuncioCardListado
                                key={`${p.esLineaGold ? 'g' : ''}${p.esLineaDestacados ? 'd' : ''}${p.referencia}`}
                                listParaGTM={props.listParaGTM}
                                propiedad={p}
                                columnas={"col-md-3 col-sm-6 col-xs-12"}
                                mostrarMapa={false}
                                propiedadHover={null}
                                propiedadOut={null}
                                arrayParaLink={null}
                                fechasDeBusqueda={[props.startDate, props.endDate]}
                                index={i}
                            />)
                        }
                    </div>
                    <div className={stringify({block, elem:"paginador"})}>
                        <PaginatorListado
                            prev={props.paginator.prev_page_url != null}
                            next={props.paginator.next_page_url != null}
                            ellipsis
                            boundaryLinks
                            items={props.paginator.last_page}
                            maxButtons={5}
                            activePage={props.paginator.current_page}
                            onSelect={props.onGoToPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}