/* @flow */
import React from 'react';
import isMobile from 'ismobilejs';
import { stringify } from 'rebem-classname';

export default class DropdownOrdenar extends React.Component{
	constructor(props) {
		super(props);
		this.onItemSeleccionado = this.onItemSeleccionado.bind(this);
	}	
	getItems(){
		return [
			{value:'aleatorio',content:'Aleatorio'},
			{value:'PD',content:'Mayor Precio'},
			{value:'PA',content:'Menor Precio'},
			{value:'DD',content:'Destacados'},
			{value:'QD',content:'Premium'},
			{value:'VD',content:'Valoración'},
			{value:'CD',content:'Comentarios'},
			{value:'RD',content:'Promoción'},
		];
	}
	onItemSeleccionado(evt){
		const valor = evt.currentTarget.getAttribute('data-value');
		this.props.onItemSeleccionado(valor);
	}
	render(){
		const block='DropdownOrdenar';
		return (
			<div className={stringify({block, mods:{mobile:isMobile.any,visible:this.props.visible}})}>
				<div className={stringify({block, elem:"items"})}>
					{this.getItems().map((item) => {
						return <div className={stringify({block, elem:"item", mods:{selected:this.props.itemSeleccionado == item.value}})} onClick={this.onItemSeleccionado} key={item.value} data-value={item.value} data-content={item.content}>
							<div className={stringify({block, elem:"item-contenido"})}>{item.content}</div>
						</div>;
					})}
				</div>
			</div>
		);
	}
}