/* @flow */
import React from 'react';
import { stringify } from 'rebem-classname';
import FiltrosDesktop from './Filtros/FiltrosDesktop.jsx';

export default function ListadoSinPropiedades(props) {
    const block = "MainListado"
    return (
        <div className={`row ${stringify({block, elem:"display"})}`} style={{ minHeight: window.innerHeight }}>
            <FiltrosDesktop
                {...props.propsFiltros}
            />
            <div className={`${props.colListado} ${stringify({block, elem:"listado"})}`}>
                <div className={stringify({block, elem:"contenedor-sin-propiedad"})}>
                    <div className={stringify({block, elem:"titulo-sin-propiedad"})}>
                        <div>No se encontraron propiedades</div>
                    </div>
                    <div className={stringify({block, elem:"subtitulo-sin-propiedad"})}>
                        <div>Trata de ajustar tu busqueda modificando los filtros</div>
                    </div>
                    <button type="button" onClick={props.showFiltro} className={`btn btn-celeste ${stringify({block, elem:"boton-eliminar-filtros"})}`}>Cambiar Filtros</button>
                </div>
            </div>

            <div className={`${props.colMapa} ${stringify({block, elem:"mapa"})}`}>
                <div className="MapaListado">
                    <div>
                        <div className="MapaListado__mapa" style={{ height: window.innerHeight }}>
                            <div className="MapaListado__imagen"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}