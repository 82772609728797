import React from "react";
import AsyncImport from "../../components/Commons/AsyncImport";
import BreadcrumListado from "./BreadcrumListado";
import BotoneraListado from "./BotoneraListado";
import ClickOutside from "react-click-outside";
import { sumarTipoVistaListadoGTM } from "../../helpers/GTMHelper";
import ListadoLocalidadSinPropiedades from "./ListadoLocalidadSinPropiedades";
import ListadoAnunciosSinPrecios from "./ListadoAnunciosSinPrecios";
import ListadoSinPropiedades from "./ListadoSinPropiedades";
import ListadoRenderItems from "./ListadoRenderItems";
import ListadoCustom from "./ListadoCustom";
import Dialog from "@mui/material/Dialog";
import Toggle from "react-toggle";
import CardListadoBannerBrasil from "./CardListado/CardListadoBannerBrasil";
import HistoryHelper from "../../helpers/HistoryHelper";
import { FaFilter } from "react-icons/fa";
import { HiOutlineHeart } from "react-icons/hi";
import { AiOutlineAlignLeft } from "react-icons/ai";
import LoginModal from "../Commons/LoginModal";
import Nav from "react-bootstrap/Nav";
// import FiltrosHeaderListado from './Filtros/FiltrosHeaderListado';
const FiltrosHeaderListado = AsyncImport({
  loader: () => import("./Filtros/FiltrosHeaderListado"),
  loading: () => null,
});
import DropdownOrdenar from "./DropdownOrdenar";
import { stringify } from "rebem-classname";
/*const DropdownOrdenar = AsyncImport({
	loader: () => import('./DropdownOrdenar'),
	loading: () => null
});*/

const GetListado = (props) => {
  let display = (
    <ListadoRenderItems
      onBuscarPagina={props.onBuscarPagina}
      listParaGTM={props.listParaGTM}
      propsFiltros={props.propsFiltros}
      showModalFavorito={props.showModalFavorito}
      estaCargando={props.estaCargando}
      colListado={props.colListado}
      bebes={props.bebes}
      destacada={props.paginator.destacada}
      desgloseDePrecio={props.paginator.desgloseDePrecio}
      colCards={props.colCards}
      colCardDestacada={props.colCardDestacada}
      personas={props.personas}
      mostrar={props.mostrar}
      arrayParaLink={props.arrayParaLink}
      propiedades={props.propiedades}
      dates={[props.startDate, props.endDate]}
      prev={props.paginator.prev_page_url}
      next={props.paginator.next_page_url}
      items={props.paginator.last_page}
      activePage={props.paginator.current_page}
      filtrosUrl={props.paginator.filtrosUrl}
      hayAnunciosSinPrecio={props.paginator.hay_anuncios_sin_precio}
      aceptaAnunciosSinPrecios={props.paginator.acepta_anuncios_sin_precios}
      listadoEspecial={props.paginator.listado_especial}
      onSelect={props.onGoToPage}
      colMapa={props.colMapa}
      lugar={props.paginator.lugar}
      showBanner={props.showBanner}
    />
  );

  if (props.propiedades <= 0) {
    display = (
      <ListadoSinPropiedades
        colListado={props.colListado}
        colMapa={props.colMapa}
        showFiltro={props.showFiltro}
        listadoSinPropiedades={props.paginator.listadoSinPropiedades}
        propsFiltros={props.propsFiltros}
      />
    );
  }
  // comento porque si solo hay anuncios sin precio ahora los mostramos en lugar de mostrar esto
  /* if (props.paginator.solo_hay_anuncios_sin_precio){
		console.log('solo hay anuncios sin precios');

		console.log(props.propiedades);

		//HistoryHelper.push('p', 1);
		//location.reload();
		display = <>
			{ 
				// si esta habilitado en config cat, si esta en el listado de brasil y si esta en la ultima pagina del listado muestro el banner
				props.showBanner && props.lugar && props.lugar.nombre == 'Brasil' && props.activePage == props.items ? 
					<CardListadoBannerBrasil /> 
				: null
			}
			<ListadoAnunciosSinPrecios
				colListado={props.colListado}
				colMapa={props.colMapa}
				propsFiltros={props.propsFiltros}
				onBuscarPagina={props.onBuscarPagina}
				paginaActual={props.paginator.current_page}
			/>
		</>
	} */

  if (props.paginator.localidadesCercanas) {
    /* lugarGenerado = {
			'pais': props.paginator.localidadSinLugar.pais,
			'provincia': props.paginator.localidadSinLugar.provincia,
			'nombre': props.paginator.localidadSinLugar.localidad,
			'tipo': "localidad",
		}; */

    //tituloSeo = 'Alquileres en ' + props.paginator.localidadSinLugar.localidad + ', ' + props.paginator.localidadSinLugar.provincia;

    display = (
      <ListadoLocalidadSinPropiedades
        colListado={props.colListado}
        dataLocalidadesCercanas={props.paginator.dataLocalidadesCercanas}
      />
    );
  }

  return display;
};

export default class MainListadoDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.showFiltro = this.showFiltro.bind(this);
    this.closeFiltro = this.closeFiltro.bind(this);
    this.toogleDropdownOrdenar = this.toogleDropdownOrdenar.bind(this);
    this.onItemSeleccionadoDropdownOrdenar =
      this.onItemSeleccionadoDropdownOrdenar.bind(this);
    this.ocultarDropdownOrdenar = this.ocultarDropdownOrdenar.bind(this);
    this.hideModalFavorito = this.hideModalFavorito.bind(this);
    this.showModalFavorito = this.showModalFavorito.bind(this);
    this.setViewCookie = this.setViewCookie.bind(this);
    this.getViewCookie = this.getViewCookie.bind(this);
    this.onOpenModalLogin = this.onOpenModalLogin.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      openLoginModal: false,
      mostrar: this.getViewCookie(),
      mostrarFiltro: false,
      propiedadHoverRef: null,
      visibleDropdownOrdenar: false,
      openModal: false,
      showBanner: false,
    };
  }
  getViewActiva(prop) {
    return this.state.mostrar == prop ? "activo" : "desactivo";
  }
  showFiltro() {
    this.setState({
      mostrarFiltro: !this.state.mostrarFiltro,
    });
  }
  showModalFavorito() {
    this.setState({
      openModal: true,
    });
  }
  onOpenModalLogin() {
    this.setState({
      openLoginModal: true,
      isVisible: false,
      openModal: false,
    });
  }
  onCloseModal() {
    this.setState({
      openLoginModal: false,
    });
  }
  hideModalFavorito() {
    this.setState({
      openModal: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.cerrarElementos) {
      this.closeFiltro();
    }
  }

  closeFiltro() {
    this.setState({
      mostrarFiltro: false,
    });
  }

  changeView(evt) {
    const mostrar = evt.currentTarget.getAttribute("data-view");
    if (mostrar != undefined) {
      this.setState({ mostrar }, () => {
        this.setViewCookie(this.state.mostrar);
        sumarTipoVistaListadoGTM(this.state.mostrar);
      });
    }
  }

  getViewCookie() {
    const vista = localStorage.getItem("v");
    switch (vista) {
      case "d":
        return "dual";
      case "m":
        return "mapa";
      case "l":
      default:
        return "listado";
    }
  }

  setViewCookie(vista) {
    let dataCookie = null;
    switch (vista) {
      case "dual":
        dataCookie = "d";
        break;
      case "mapa":
        dataCookie = "m";
        break;
      case "listado":
      default:
        dataCookie = "l";
        break;
    }
    localStorage.setItem("v", dataCookie);
  }

  toogleDropdownOrdenar() {
    this.setState({
      visibleDropdownOrdenar: !this.state.visibleDropdownOrdenar,
    });
  }
  onItemSeleccionadoDropdownOrdenar(orden) {
    //TODO: Comunicar con onBuscador
    this.props.onOrdenChange(orden);
    this.ocultarDropdownOrdenar();
    this.closeFiltro();
  }
  ocultarDropdownOrdenar() {
    this.setState({ visibleDropdownOrdenar: false });
  }
  render() {
    const propsFiltros = {
      showFiltro: this.showFiltro,
      onClickCheckFiltros: this.props.onClickCheckFiltros,
      ofertasOnChangeFiltros: this.props.ofertasOnChangeFiltros,
      previajeOnChangeFiltros: this.props.previajeOnChangeFiltros,
      reservaOnChangeFiltros: this.props.reservaOnChangeFiltros,
      onChangePrecioFiltros: this.props.onChangePrecioFiltros,
      onBuscar: this.props.onBuscar,
      onRestablecerFiltros: this.props.onRestablecerFiltros,
      onLugarSelected: this.props.onLugarSelected,
      onLugarClear: this.props.onLugarClear,
      onClickCheckLocalidadesFiltros: this.props.onClickCheckLocalidadesFiltros,
      onMinus: this.props.onMinus,
      onPlus: this.props.onPlus,
      mostrarFiltro: this.state.mostrarFiltro,
      localidades: this.props.paginator.localidadesFiltro,
      cochera: this.props.cochera,
      banos: this.props.banos,
      dormitorios: this.props.dormitorios,
      conPileta: this.props.conPileta,
      sinPileta: this.props.sinPileta,
      PiletaExclusiva: this.props.PiletaExclusiva,
      Piletaclimatizada: this.props.Piletaclimatizada,
      Casas: this.props.Casas,
      Departamentos: this.props.Departamentos,
      Cabanas: this.props.Cabanas,
      Aparts: this.props.Aparts,
      Hoteleria: this.props.Hoteleria,
      Quintas: this.props.Quintas,
      Motorhomes: this.props.Motorhomes,
      Glampings: this.props.Glampings,
      ofertas: this.props.ofertas,
      previaje: this.props.previaje,
      reserva: this.props.reserva,
      conMascotas: this.props.conMascotas,
      conJovenes: this.props.conJovenes,
      wifi: this.props.wifi,
      tarjeta: this.props.tarjeta,
      desayuno: this.props.desayuno,
      limpieza: this.props.limpieza,
      toallas: this.props.toallas,
      sabanas: this.props.sabanas,
      spa: this.props.spa,
      juegosParaNinios: this.props.juegosParaNinios,
      restaurante: this.props.restaurante,
      min: this.props.min,
      max: this.props.max,
      currentValues: this.props.currentValues,
      servicios: this.props.servicios,
      agregado1: this.props.agregado1,
      agregado2: this.props.agregado2,
      agregado3: this.props.agregado3,
      filtrosAplicados: this.props.filtrosAplicados,
      localidades_seleccionadas: this.props.localidades_seleccionadas,
      localidad: this.props.localidad,
      referencia: this.props.referencia,
      barrio: this.props.barrio,
      lugar: this.props.lugar,
      showFiltro: this.showFiltro,
      onReferenciaChange: this.props.onReferenciaChange,
      onBarrioSelected: this.props.onBarrioSelected,
      onBarrioClear: this.props.onBarrioClear,
      aireAcondicionado: this.props.aireAcondicionado,
      calefaccion: this.props.calefaccion,
      microondas: this.props.microondas,
      televisor: this.props.televisor,
      lavarropa: this.props.lavarropa,
      alojamientoAccesible: this.props.alojamientoAccesible,
      alojamientosPremium: this.props.alojamientosPremium,
      alojamientosDestacados: this.props.alojamientosDestacados,
      seleccionesOtros: this.props.seleccionesOtros,
      hayTroyaEnElListado: this.props.paginator.hayTroyaEnElListado,
      precioPromedio: this.props.precioPromedio,
    };
    const block = "MainListado";
    let colListado = "";
    let colCards = "";
    let colMapa = "";
    let colCardDestacada = "";

    switch (this.state.mostrar) {
      // cuando está en modo dual (ojo, en movil sigue quedando en 'dual')
      case "dual":
        //el listado se lleva 2 tercios en md
        colListado = "col-md-8 col-xs-12";
        //y el mapa el tercio restante
        colMapa = "col-md-4";
        //entran 3 cards
        colCards = "col-md-4";
        colCardDestacada = "col-md-offset-4";
        break;
      case "mapa":
        //en vista mapa, no se ve el listado
        colListado = "d-none";
        //y el mapa ocupa todo
        colMapa = "col-md-12";
        //entran 4 cards
        colCards = "col-md-3";
        colCardDestacada = "col-md-offset-3";
        break;
      case "listado":
        //el listado ocupa todo
        colListado = "col-md-12";
        //y el mapa no se ve
        colMapa = "d-none";
        //entran 4 cards
        colCards = "col-md-3";
        colCardDestacada = "col-md-offset-3";
        break;
      default:
        throw new Error("no se ha seteado un modo para el listado");
    }

    let arrayParaLink = null;
    let lugarGenerado = null;
    let tituloSeo = this.props.paginator.tituloSeo;
    const modalLogin = this.state.openLoginModal && (
      <LoginModal onCloseLoginModal={this.onCloseModal} />
    );

    return !this.props.paginator.listadoCustom ? (
      <div className={`container-fluid ${stringify({ block })}`}>
        <Dialog open={this.state.openModal} onClose={this.hideModalFavorito}>
          <div className={stringify({ block, elem: "contenido-modal-fav" })}>
            <div className={stringify({ block, elem: "texto-modal-fav" })}>
              Para usar la sección FAVORITOS debes estar logueado a la página.
            </div>
            <div className={stringify({ block, elem: "texto-modal-fav" })}>
              Para iniciar sesión o registrarte haz click en acceder.
            </div>
            <Nav.Link
              onClick={this.onOpenModalLogin}
              className="btn btn-naranja"
            >
              Ingresar
            </Nav.Link>
          </div>
        </Dialog>
        {modalLogin}
        <div className={`row ${stringify({ block, elem: "innerRow" })}`}>
          <div className={`col-md-6 ${stringify({ block, elem: "header" })}`}>
            <div className={stringify({ block, elem: "breadcumb" })}>
              <BreadcrumListado
                lugar={this.props.breadcrum || lugarGenerado}
                cantidad={this.props.breadcrum ? this.props.paginator.total : 0}
              />
            </div>
            <h1 className={stringify({ block, elem: "descripcion" })}>
              {tituloSeo}
            </h1>
            <div className={stringify({ block, elem: "filtros" })}>
              {false && (
                <FiltrosHeaderListado
                  filtrosAplicados={this.props.filtrosParaHeader}
                  onRestablecerFiltros={this.props.onRestablecerFiltros}
                  onRestablecerFiltro={this.props.onRestablecerFiltro}
                  onBuscar={this.props.onBuscar}
                />
              )}
            </div>
          </div>
          {!this.props.paginator.localidadesCercanas ? (
            <div
              className={`col-md-6 ${stringify({ block, elem: "botonera" })}`}
            >
              <div className={stringify({ block, mods: { iconos: true } })}>
                <div
                  className={stringify({
                    block,
                    elem: "reserva-online-toggle",
                  })}
                >
                  <span className={stringify({ block, elem: "texto-toggle" })}>
                    SÓLO RESERVA ONLINE
                  </span>
                  <Toggle
                    checked={this.props.reserva}
                    onChange={this.props.soloReservaOnlineOnChangeFiltros}
                  />
                </div>

                <BotoneraListado
                  onClickEvent={this.showFiltro}
                  icono={<FaFilter />}
                  titulo="FILTROS"
                />

                <ClickOutside
                  style={{ display: "inline-block" }}
                  onClickOutside={this.ocultarDropdownOrdenar}
                >
                  <BotoneraListado
                    onClickEvent={this.toogleDropdownOrdenar}
                    icono={<AiOutlineAlignLeft />}
                    titulo="ORDENAR"
                  />
                  <DropdownOrdenar
                    visible={this.state.visibleDropdownOrdenar}
                    onItemSeleccionado={this.onItemSeleccionadoDropdownOrdenar}
                    itemSeleccionado={this.props.orden}
                  />
                </ClickOutside>

                <div className={stringify({ block, elem: "iconos-item" })}>
                  <a href="/favoritos/">
                    <div
                      className={stringify({ block, elem: "iconos-iconos" })}
                    >
                      <HiOutlineHeart />
                    </div>
                    <div
                      className={stringify({ block, elem: "iconos-titulo" })}
                    >
                      <span>FAVORITOS</span>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className={`float-end ${stringify({ block, elem: "botones" })}`}
              >
                <div className="btn-group" role="group" aria-label="...">
                  <button
                    type="button"
                    className={
                      "btn btn-default MainListado_" +
                      this.getViewActiva("dual")
                    }
                    data-view="dual"
                    onClick={this.changeView}
                  >
                    DUAL
                  </button>
                  <button
                    type="button"
                    className={
                      "btn btn-default MainListado_" +
                      this.getViewActiva("mapa")
                    }
                    data-view="mapa"
                    onClick={this.changeView}
                  >
                    MAPA
                  </button>
                  <button
                    type="button"
                    className={
                      "btn btn-default MainListado_" +
                      this.getViewActiva("listado")
                    }
                    data-view="listado"
                    onClick={this.changeView}
                  >
                    LISTA
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <GetListado
          {...this.props}
          mostrar={this.state.mostrar}
          colMapa={colMapa}
          colListado={colListado}
          showFiltro={this.showFiltro}
          showBanner={this.state.showBanner}
          propsFiltros={propsFiltros}
          arrayParaLink={arrayParaLink}
          colCards={colCards}
          colCardDestacada={colCardDestacada}
          showModalFavorito={this.showModalFavorito}
        />
      </div>
    ) : (
      <ListadoCustom {...this.props} />
    );
  }
}
