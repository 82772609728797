import React from 'react';
import { stringify } from 'rebem-classname';
import ContenidoFiltros from './ContenidoFiltros.jsx';

export default function FiltrosDesktopContenido(props){
	const block = 'FiltrosDesktopContenido';
	const propsFiltros = {
			localidades:props.localidades,
			cochera:props.cochera,
			banos:props.banos,
			dormitorios:props.dormitorios,			
			onBuscar:props.onBuscar,
			onRestablecerFiltros:props.onRestablecerFiltros,
			ofertasOnChange: props.ofertasOnChangeFiltros,
			previajeOnChange: props.previajeOnChangeFiltros,
			reservaOnChange:props.reservaOnChangeFiltros,
			onChangePrecio:props.onChangePrecioFiltros,
			conPileta:props.conPileta,
			sinPileta:props.sinPileta,
			PiletaExclusiva:props.PiletaExclusiva,
			Piletaclimatizada:props.Piletaclimatizada,
			Casas:props.Casas,
			Departamentos:props.Departamentos,
			Cabanas:props.Cabanas,
			Aparts:props.Aparts,
			Hoteleria:props.Hoteleria,
			Quintas:props.Quintas,
			Motorhomes:props.Motorhomes,
			Glampings:props.Glampings,
			reserva: props.reserva,
			ofertas:props.ofertas,
			previaje: props.previaje,
			conMascotas:props.conMascotas,
			conJovenes:props.conJovenes,
			wifi:props.wifi,
			tarjeta:props.tarjeta,
			desayuno:props.desayuno,
			limpieza:props.limpieza,
			toallas:props.toallas,
			sabanas:props.sabanas,
			spa:props.spa,
			juegosParaNinios:props.juegosParaNinios,
			restaurante:props.restaurante,
			min:props.min,
			max:props.max,
			currentValues:props.currentValues,
			servicios: props.servicios,
			agregado1:props.agregado1,
			agregado2:props.agregado2,
			agregado3:props.agregado3,
			filtrosAplicados:props.filtrosAplicados,
			localidades_seleccionadas:props.localidades_seleccionadas,
			localidad:props.localidad,
			referencia:props.referencia,
			barrio:props.barrio,
			onLugarSelected:props.onLugarSelected,
			onLugarClear:props.onLugarClear,
			lugar:props.lugar,
			onReferenciaChange:props.onReferenciaChange,
			onBarrioSelected:props.onBarrioSelected,
			onBarrioClear:props.onBarrioClear,
			aireAcondicionado:props.aireAcondicionado,
			calefaccion:props.calefaccion,
			microondas:props.microondas,
			televisor:props.televisor,
			lavarropa:props.lavarropa,
			alojamientoAccesible:props.alojamientoAccesible,
			alojamientosPremium:props.alojamientosPremium,
			alojamientosDestacados:props.alojamientosDestacados,
			seleccionesOtros:props.seleccionesOtros,
			hayTroyaEnElListado:props.hayTroyaEnElListado,
			precioPromedio:props.precioPromedio
		}
	return (
		<div className={stringify({block})}>
			<div className={stringify({block, elem:"close"})}>
				<span onClick={props.showFiltro} className="glyphicon glyphicon-remove"/>
			</div>
			<ContenidoFiltros
				onClickCheck={props.onClickCheck}
				onClickCheckLocalidades={props.onClickCheckLocalidades}
				{...propsFiltros}
				onPlus={props.onPlus}
				onMinus={props.onMinus}
			/>
		</div>
	);
}